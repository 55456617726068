
export default {
    data() {
        return {
            dragging: false,
            supportsTouch: false
        };
    },

    computed: {
        enableDragToScroll() {
            return !this.supportsTouch;
        }
    },

    mounted() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            this.supportsTouch = true;
        }
    },

    methods: {
        handleDragStart() {
            this.dragging = true;
        },
        handleDragEnd() {
            this.dragging = false;
        }
    }
};
