
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin
    ],

    props: {
        sectionTitle: {
            type: String,
            default: null
        },

        description: {
            type: String,
            default: null
        },

        stories: {
            type: Array,
            default: null
        },

        callToAction: {
            type: Array,
            default: null
        }
    }
};
