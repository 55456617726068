
export default {
    props: {
        icon: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        }
    }
};
